<template>
  <v-container>
    <loader v-if="loading" />
    <div class="custom-card">
      <div class="custom-card-title header">
        Таблица "Системы лимитов у товара"
      </div>
      <div class="custom-card-btn">
        <v-btn
          color="primary"
          class="ml-4"
          @click="
            modal = !modal;
            editId = 0;
          "
          >Добавить</v-btn
        >
      </div>
      <div class="custom-card__container">
        <div class="custom-table">
          <div class="head">
            <div v-for="item in items.head" :key="item.id" class="head-item">
              {{ item.name }}
            </div>
          </div>
          <div v-for="item in items.body" :key="item.id" class="value">
            <div class="value-item">
              {{ item.id }}
            </div>
            <div class="value-item">
              {{ item.measure.name }}
            </div>
            <div class="value-item">
              {{ item.keyName }}
            </div>
            <div class="value-item">
              {{ item.quantity }}
            </div>
            <div class="value-item">
              <v-btn
                color="secondary"
                class="ml-6"
                icon
                @click="
                  modal = !modal;
                  editId = item.id;
                "
                ><v-icon>mdi-lead-pencil</v-icon></v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <LimitControlDialog
      :id="editId"
      :show-modal="modal"
      @rerender="renderPage()"
    />
  </v-container>
</template>

<script>
import loader from "@/components/Loader";
import LimitControlDialog from "@/components/Limits/LimitControlDialog.vue";
import { mapActions } from "vuex";

export default {
  components: {
    loader,
    LimitControlDialog,
  },
  data() {
    return {
      loading: false,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Ед. измерения" },
          { id: 3, name: "keyName" },
          { id: 4, name: "Кол-во" },
          { id: 5, name: "Редактировать" },
        ],
        body: [],
      },
      modal: false,
      editId: 0,
    };
  },
  created() {
    this.renderPage();
  },

  methods: {
    ...mapActions({
      getAllMinimalQuantity: "Products/GET_ALL_MINIMAL_QUANTITY",
    }),
    async renderPage() {
      this.loading = true;
      const response = await this.getAllMinimalQuantity();
      this.items.body = response.data;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  background: #ffffff;
  padding: 40px;
  position: relative;
  .custom-card-title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 24px;
  }
  .custom-card-btn {
    position: absolute;
    right: 40px;
    top: 40px;
  }
  .custom-card__container {
    .custom-table {
      .head {
        display: flex;
        justify-content: space-between;
        .head-item {
          flex: 1;
          text-align: left;
          font-weight: 500;
        }
      }
      .value {
        display: flex;
        justify-content: space-between;
        .value-item {
          flex: 1;
          text-align: left;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
