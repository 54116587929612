<template>
  <v-dialog v-model="show" activator="parent" width="50%">
    <SingleComponent
      :loading="loading"
      :mode="mode"
      modal-type
      @add-button="addButton"
      @edit-button="editButton"
      @close-modal="show = false"
    >
      <v-text-field
        v-model="input.keyName"
        label="keyName"
        :rules="[$validate.required]"
      >
      </v-text-field>
      <v-select
        v-model="input.measureId"
        label="Ед.измерения"
        :items="MEASURE.allMeasure"
        item-value="id"
        item-text="name"
        :rules="[$validate.required]"
      ></v-select>
      <v-text-field
        v-model="input.quantity"
        label="Кол-во"
        :rules="[$validate.required]"
      >
      </v-text-field>
    </SingleComponent>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SingleComponent from "@/components/SingleComponent";

export default {
  name: "LimitControlDialog",
  components: {
    SingleComponent,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      mode: "Добавить",
      input: {
        keyName: "",
        measureId: "",
        quantity: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      MEASURE: "Measure/STATE",
    }),
  },
  watch: {
    showModal() {
      this.show = !this.show;
    },
    id(val) {
      if (val !== 0) {
        this.mode = "Редактировать";
        const response = this.getByIdQuantity(this.id);
        response.then((res) => {
          this.input.measureId = res.data.measure.id;
          this.input.keyName = res.data.keyName;
          this.input.quantity = res.data.quantity;
        });
      } else {
        this.input = {
          keyName: "",
          measureId: "",
          quantity: "",
        };
      }
    },
  },
  mounted() {
    this.getAllMeasure();
  },

  methods: {
    ...mapActions({
      addMinimalQuantity: "Products/ADD_MINIMAL_QUANTITY",
      getAllMeasure: "Measure/GET_ALL_MEASURE",
      getByIdQuantity: "Products/GET_BYID_MINIMAL_QUANTITY",
      editMinimalQuantity: "Products/EDIT_MINIMAL_QUANTITY",
    }),
    async addButton() {
      if (this.mode === "Добавить") {
        this.loading = true;
        let response = await this.addMinimalQuantity(this.input);
        if (response.type === "success") {
          this.$emit("rerender");
          this.show = false;
        } else {
          this.$notify({
            group: "app",
            type: "error",
            title: "Ошибка",
            text: response.text,
          });
        }
        this.loading = false;
      } else {
        this.editButton;
      }
    },
    async editButton() {
      this.loading = true;
      this.input.id = this.id;
      let response = await this.editMinimalQuantity(this.input);
      if (response.type === "success") {
        this.$emit("rerender");
        this.show = false;
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.text,
        });
      }
      this.loading = false;
    },
  },
};
</script>
