<template>
  <v-card style="border-radius: 0" :dark="$dark.get()" elevation="1">
    <loader v-if="loading"></loader>
    <v-card-title class="header">
      {{ $route.name }}
    </v-card-title>
    <v-card-text class="pa-0 pt-4">
      <v-form ref="single">
        <slot />
      </v-form>
    </v-card-text>
    <v-card-actions class="pr-16 pl-16 pb-15">
      <v-spacer></v-spacer>
      <v-btn
        class="pl-5 pr-5"
        color="#A4A4A4"
        outlined
        @click="modalType ? $emit('close-modal') : $router.go(-1)"
      >
        Отмена
      </v-btn>
      <v-btn color="#5d4bd0" dark @click="buttonSwitch()">{{ mode }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Loader from "@/components/Loader";
export default {
  components: { Loader },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "",
    },
    modalType: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    buttonSwitch() {
      if (this.$refs.single.validate()) {
        if (this.mode === "Добавить") {
          this.$emit("add-button");
        } else {
          this.$emit("edit-button");
        }
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка валидации",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.header {
  background: linear-gradient(to right, #e93e7b 50%, white 70%);
  margin-left: -12px;
  color: white;
  padding: 5px 10px 5px 70px;
  @media (max-width: 500px) {
    margin-left: 0px;
    padding: 5px;
    justify-content: center;
    background: linear-gradient(90deg, #e93e7b 70%, white 90%);
  }
}
</style>
